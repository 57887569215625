<template>
  <div class='container'>
    <div class="options">
      <div class="option">
        <div class="title">姓名</div>
        <input type="text" v-model="formData.trueName" placeholder="请输入学生姓名">
      </div>
      <div class="option">
        <div class="title">年级</div>
        <div class="select">
          <input type="text" v-model="grade" placeholder="请选择学生年级" @click="showPopup('grade')">
          <van-icon class="arrow" name="arrow-down" size="5.3333vw"/>
        </div>
      </div>
      <div class="option">
        <div class="title">班级</div>
        <div class="class">
          <input type="number" v-model="studyClass" placeholder="请输入学生班级">
          <p>班</p>
        </div>
      </div>
      <div class="option">
        <div class="title">学籍号</div>
        <input type="text" v-model="formData.sn" placeholder="请输入学生学籍号">
      </div>
      <div class="option">
        <div class="title">性别</div>
        <div class="genders">
          <div :class="['gender',formData.gender===0?'active':'']" @click="genderChange(0)">女</div>
          <div :class="['gender',formData.gender===1?'active':'']" @click="genderChange(1)">男</div>
        </div>
      </div>
      <div class="option">
        <div class="title">出生年月</div>
        <div class="select">
          <input type="text" v-model="this.formData.birthday" placeholder="请选择学生出生年月" @click="showPopup('birthday')">
          <van-icon class="arrow" name="arrow-down" size="5.3333vw"/>
        </div>
      </div>
      <div class="option">
        <div class="title">身高(cm)</div>
        <input type="text" v-model="this.formData.height" placeholder="请选择学生身高">
      </div>
      <div class="option">
        <div class="title">体重(kg)</div>
        <input type="text" v-model="this.formData.weight" placeholder="请选择学生体重">
      </div>
      <div class="option">
        <div class="title">民族</div>
        <div class="select">
          <input type="text" v-model="this.formData.nation" placeholder="请选择学生民族" @click="showPopup('nation')">
          <van-icon class="arrow" name="arrow-down" size="5.3333vw"/>
        </div>
      </div>
      <div class="option">
        <div class="title">籍贯</div>
        <input type="text" v-model="formData.birth_place" placeholder="请输入学生籍贯" >
      </div>
    </div>
    <div class="bottom">
      <button @click="submit">保存</button>
    </div>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="年级"
        value-key="gradeName"
        show-toolbar
        :columns="gradeList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='grade'"
      />
      <van-datetime-picker
        type="date"
        title="选择年月日"
        show-toolbar
        v-model="currentDate"
        :max-date="maxDate"
        :min-date="minDate"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='birthday'"
      />
      <!-- <van-picker
        title="出生年月"
        show-toolbar
        :columns="healthList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='birthday'"
      /> -->
      <van-picker
        title="民族"
        show-toolbar
        :columns="nationList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='nation'"
      />
    </van-popup>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {profileUpdateV2} from '@/api/student.js'
import {gradeAndStudyClass} from '@/api/report.js'
import moment from 'moment'
export default {
  data(){
    return{
      gradeList:[],
      currentDate:new Date(),
      nationList:[
        "汉族",
        "蒙古族",
        "回族",
        "藏族",
        "维吾尔族",
        "苗族",
        "彝族",
        "壮族",
        "布依族",
        "朝鲜族",
        "满族",
        "侗族",
        "瑶族",
        "白族",
        "土家族",
        "哈尼族",
        "哈萨克族",
        "傣族",
        "黎族",
        "傈僳族",
        "佤族",
        "畲族",
        "高山族",
        "拉祜族",
        "水族",
        "东乡族",
        "纳西族",
        "景颇族",
        "柯尔克孜族",
        "土族",
        "达斡尔族",
        "仫佬族",
        "羌族",
        "布朗族",
        "撒拉族",
        "毛难族",
        "仡佬族",
        "锡伯族",
        "阿昌族",
        "普米族",
        "塔吉克族",
        "怒族",
        "乌孜别克族",
        "俄罗斯族",
        "鄂温克族",
        "德昂族",
        "保安族",
        "裕固族",
        "京族",
        "塔塔尔族",
        "独龙族",
        "鄂伦春族",
        "赫哲族",
        "门巴族",
        "珞巴族",
        "基诺族"
      ],
      maxDate:new Date(),
      minDate:new Date(1900, 1, 1),
      popupName:'',
      isPopup:false,
      formData: {
          id: '',
          sn: '', // 学籍号
          grade: '', // 年级
          studyClass: '', // 班级
          gender: '', // 性别
          area: '', // 校区
          birthday: '', // 出生日期
          nation: '', // 民族
          birth_place: '', // 籍贯
          situation: '', // 学习情况
          interest: [], // 兴趣
          interest_other: '', // 兴趣
          home_address: '', // 家庭地址
          health_status: '', // 健康状况
          psychological_diagnsis: '', // 心理诊断记录
          past_medical_history: [], // 过往身体病史
          past_medical_history_other: '', // 过往身体病史
          parent_relationship: '', // 父母关系
          family_atmosphere: '', // 家庭气氛
          family_economy: '', // 家庭经济情况
          other: '', // 其他情况
          f_name: '', // 父亲名字
          f_age: '', // 年龄
          f_occupation: '', // 职业
          f_characteristics: '', // 个人特点
          f_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          m_name: '', // 母亲名字
          m_age: '', // 年龄
          m_occupation: '', // 职业
          m_characteristics: '', // 个人特点
          m_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          study: [], // 学习经历
          event: [], // 重大生活事件
          home: [], // 家庭成员
          province: '', // 省
          city: '', // 市
          county: '' // 区
        },
    }
  },
  computed:{
    ...mapState(['student']),
    grade(){
      let grade=''
      this.gradeList.forEach(item=>{
        if(item.grade===this.formData.grade){
          grade=item.gradeName
        }
      })
      return grade
    },
    studyClass:{
      get() {
      return Number(this.formData.studyClass.replace(new RegExp('班', "g"), ""))
       },
       set(value) {
         this.formData.studyClass = value;
       }
    },
  },
  created(){
    this.getDetail()
    this.getGradeOrClass()
  },
  methods:{
    async getDetail(){
      const {id}=this.$route.query
      await this.$store.dispatch('getStudentDetail',id)
      this.formData=this.student
    },
    // 获取年级
    async getGradeOrClass(){
      const res = await gradeAndStudyClass()
      this.gradeList=res.data.grades
    },
    showPopup(popupName){
      this.popupName=popupName
      this.isPopup=true
    },
    genderChange(gender){
      this.formData.gender=gender
    },
    onConfirm(val){
      if(this.popupName==='grade'){
        this.formData.grade=val.grade
      }else if(this.popupName==='birthday'){
        const date = moment(val, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z');
        this.formData.birthday=date.format('YYYY-MM-DD')
      }else if(this.popupName==='nation'){
        this.formData.nation=val
      }
      console.log(val);
      this.isPopup=false
    },
    async submit(){
      if(!this.formData.trueName){
        this.$toast.fail('学生姓名不能为空')
        return
      }
      if(!this.formData.grade){
        this.$toast.fail('年级不能为空')
        return
      }
      if(!this.formData.studyClass){
        this.$toast.fail('班级不能为空')
        return
      }
      if(!this.formData.sn){
        this.$toast.fail('学籍号')
        return
      }
      if(this.formData.gender!==0&&this.formData.gender!==1){
        this.$toast.fail('性别不能为空')
        return
      }
      if(!this.formData.birthday){
        this.$toast.fail('出生年月不能为空')
        return
      }
      if(!this.formData.nation){
        this.$toast.fail('名族不能为空')
        return
      }
      if(!this.formData.birth_place){
        this.$toast.fail('籍贯不能为空')
        return
      }
      const formData=this.formData
      formData.studyClass=formData.studyClass+'班'
      const res = await profileUpdateV2(formData)
      this.$toast.success('修改成功')
      this.$router.back()
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  min-height: 100%;
  padding-top: 5.3333vw;
  padding-bottom: 18.6667vw;
  .options{
    padding: 0 4vw;
    .option{
      margin-bottom: 4vw;
      .title{
        font-weight: 550;
        margin-bottom: 3.4667vw;
      }
      .select{
        position: relative;
        input{
          padding-right: 10.6667vw;
        }
        .arrow{
          position: absolute;
          top: 50%;
          right: 4vw;
          transform: translateY(-50%);
        }
      }
      .class{
        display: flex;
        align-items: center;
        input{
          flex: 1;
          margin-right: 4vw;
        }
        p{
          font-size: 4vw;
          font-weight: 550;
        }
      }
      .genders{
        display: flex;
        align-items: center;
        .gender{
          width: 21.3333vw;
          height: 12.2667vw;
          line-height: 12.2667vw;
          text-align: center;
          background: #F5F7F9;
          margin-right: 4vw;
          border-radius: 1.3333vw;
          font-size: 4vw;
        }
        .gender.active{
          color: #fff;
          background-color: #5EDBC9;
        }
      }
      input{
        padding: 0 6.1333vw;
        width: 100% ;
        height: 12.2667vw;
        border-radius: 13.3333vw;
        background: #F5F7F9;
      }
    }
  }
  .bottom{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    bottom: 0;
    width: 100%;
    height: 16vw;
    button{
      width: 100%;
      color: #fff;
      height:13.8667vw;
      line-height: 13.8667vw;
      font-size: 4vw;
      border-radius: 13.3333vw;
      background: #5EDBC9;
    }
  }
}
</style>